<template>
  <div class="container">
    <div class="members">
      <v-row class="">
        <v-col cols="12" class="top-panel px-7">
          <div class="arrow-btn">
            <router-link
              style="margin: auto"
              v-if="
                user &&
                user.roles &&
                user.roles.length > 0 &&
                user.roles[0].employeeRole === 'Root'
              "
              to="/UserMembers"
            >
              <img
                src="../assets/img/arrowBack.svg"
                class="img-pluss-btn"
                alt="arrow-back"
              />
            </router-link>
            <router-link style="margin: auto" v-else to="/RestaurantChoose">
              <img
                src="../assets/img/arrowBack.svg"
                class="img-pluss-btn"
                alt="arrow-back"
              />
            </router-link>
            <h1 class="page-title">Пользователь</h1>
          </div>
          <div class="d-flex align-center">
            <p class="warning-red">Есть несохраненые изменения!</p>
            <button class="btn-save">Удалить Пользователя</button>
            <button class="btn-save">
              Сохранить
              <img
                class="save-icon"
                src="../assets/img/btn-save.svg"
                alt="save"
              />
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row class="container-margin">
        <v-col cols="4">
          <div class="content-container">
            <v-col cols="12">
              <h2 class="information-title">Информация</h2>
            </v-col>
            <div class="horizontal-line"></div>
            <v-col cols="12" class="pb-0">
              <p style="margin-left: 5px" class="information-title-text">
                Название ресторана<span class="red-dot">*</span>
              </p>
            </v-col>
            <v-col cols="12" class="px-2 pb-0 pt-0">
              <input
                style="width: 100%"
                class="col-description"
                v-model="descAdress"
                ref="descAdressTextarea"
                :placeholder="isdescAdressFocused ? '' : textareaPlaceholder"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <p style="margin-left: 5px" class="col-title">Имя Фамилия</p>
            </v-col>
            <v-col cols="12" class="px-2 pt-0 pb-2">
              <input
                style="width: 100%"
                class="col-description"
                v-model="infoAdress"
                ref="infoAdressTextarea"
                :placeholder="isinfoAdressFocused ? '' : textareaPlaceholder"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <p style="margin-left: 5px" class="col-title">
                Должность
              </p>
            </v-col>
            <v-col cols="12" class="px-2 pt-0 pb-0">
              <input
                style="width: 100%"
                class="col-description"
                v-model="instagram"
                ref="instagramTextarea"
                :placeholder="isinstagramFocused ? '' : textareaPlaceholder"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <p style="margin-left: 5px" class="information-title-text">
                Номер Телефона
              </p>
            </v-col>
            <v-col cols="12" class="px-2 pt-0 pb-2">
              <input
                style="width: 100%"
                class="col-description"
                v-model="phone"
                ref="phoneTextarea"
                v-mask="'+7 (###) ###-##-##'"
                type="tel"
                :placeholder="isphoneFocused ? '' : textareaPlaceholder"
              />
            </v-col>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style scoped>
.information-title-text {
  color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 0px;
}
.information-title-text-special {
  color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: left;
}
.container-margin {
  margin: auto;
}
.page-title {
  color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0%;
  text-align: left;
  margin-left: 16px;
}
.warning-red {
  color: rgb(228, 19, 19);
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 0px;
}
.btn-save {
  display: flex;
  align-items: center;
  padding: 5px 18px 5px 18px;
  margin-left: 37px;
  cursor: pointer;
  border-radius: 5px;
  background: rgb(50, 60, 77);
  height: 36px;
  color: white;
  margin-left: 20px;
}
.save-icon {
  margin-left: 5px;
}
.mt-top {
  margin-top: 23px;
  margin-bottom: 0px;
}
.uploaded-image-container {
  cursor: pointer;
}
.container-img {
  max-width: 100%; /* Гарантировать, что изображение не будет больше контейнера */
  max-height: 100%;
}
.my-dialog {
  background: white;
  border-width: 0px 30px 30px 30px;
  border-style: none solid solid solid;
  border-color: transparent white white white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden; /* Скрыть изображение, которое выходит за пределы контейнера */
}
.close-btn {
  cursor: pointer;

  text-align: end;
}
.modal-image {
  width: 100%; /* Сделать изображение 100% ширины контейнера */
  height: auto;
  cursor: pointer; /* Сохранить соотношение сторон изображения */
}

.content-container-img {
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  background: var(--white, #fff);
  margin-top: 0px;
  /* height: 270px; */
}
.col-content-img {
  display: flex;
  /* align-items: center; */
}
.product-item__image {
  width: 100px;
  height: 100px;
}
.img-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.uploaded-image-logo {
  max-width: 100px; /* Adjust this value as needed */
  max-height: 100px; /* Adjust this value as needed */
  margin-right: 21px;
}
.horizonmargin-bottom {
  margin-bottom: 39px;
}
.zone-price:focus {
  outline: none;
}
.zone-price {
  width: 70%;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  border: none;
  padding-left: 12px;
}

.time-start {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}

.zone-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-menu-item:hover {
  /* Здесь вы можете задать стили для hover-эффекта */
  background-color: #f0f0f0; /* Пример: изменение цвета фона при наведении */
  color: #333; /* Пример: изменение цвета текста при наведении */
  cursor: pointer; /* Пример: изменение курсора при наведении */
  /* Другие стили по вашему усмотрению */
}
.border-input {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
  padding: 6px;
}
.border-white {
  border-radius: 8px;
  border: 1px solid var(--02, white);
  background: var(--white, #fff);
  padding: 6px;
  cursor: pointer;
  width: 100%;
}
.divider-modal {
  background: #e0e0e0;
  height: 1px;
  width: 100%; /* Расширяем на всю ширину родительского контейнера */
}

.profile-menu-item {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  padding-left: 30px;
  padding-top: 11px;
  padding-bottom: 11px;
  width: 100%;
}

.input-menu {
  position: absolute;
  top: 362px;
  left: 11%;
  width: 340px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(200, 200, 200, 0.52);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  /* right: -15px; */
}
.select-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  /* margin-left: 15px; */
}
.option-btn {
  margin-bottom: 0px;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-left: 5px;
}
.role-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  /* Другие стили по вашему усмотрению */
}
.v-col.cols-7 .border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
}
input:focus {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
}
input:hover {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
  cursor: pointer;
}
textarea:focus {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
}
textarea:hover {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
  cursor: pointer;
}
.full-text-input,
:focus {
  outline: none; /* Убираем внешний контур (outline) при фокусе */
  border: none; /* Убираем границу (border) при фокусе */
  box-shadow: none; /* Убираем тень (box-shadow) при фокусе */
}
.requisites-text-container {
  display: flex !important;
  flex-direction: row;
  /* justify-content: space-evenly; */
  align-items: baseline;
}
.requisites-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.margin-logo-divider {
  margin-bottom: 48px;
}
.logo-dot-3 {
  margin-right: 13px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.logo-dot-2 {
  margin-right: 16px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.logo-dot {
  margin-right: 69px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.custom-file-upload {
  cursor: pointer;
  margin-left: 18px;
}
.logo-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploaded-image-logo {
  max-width: 100px; /* Adjust this value as needed */
  max-height: 100px; /* Adjust this value as needed */
  margin-right: 21px;
}
.uploaded-image-header {
  max-width: 100px; /* Adjust this value as needed */
  max-height: 100px; /* Adjust this value as needed */
  margin-right: 21px;
}
.uploaded-image-photo-rest {
  max-width: 100px; /* Adjust this value as needed */
  max-height: 100px; /* Adjust this value as needed */
  margin-right: 21px;
}
.work-margin-img {
  margin-bottom: 15px;
}
.work-img {
  width: 22px;
  height: 22px;
}
.col-img {
  flex-direction: column-reverse;
  display: flex;
}
.work-margin {
  padding-bottom: 15px;
}
.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px; /* Толщина линии */
  background: #848484; /* Цвет линии (в данном случае черный) */
  margin-right: 12px;
  margin-left: 12px;
}
.horizontal-line-img {
  height: 1px;
  background: #848484;
  margin-right: 12px;
  margin-left: 12px;
  margin-top: 29px;
  margin-bottom: 44px;
}
.tel-pointer {
  cursor: pointer;
}
.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
}
.col-paddings-t-b {
  padding-top: 0px;
  padding-bottom: 0px;
}
.col-content {
  display: flex;
  align-items: flex-start;
}
.col-description {
    color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  min-height: 45px; /* Минимальная высота для textarea */
  padding: 10px;
}
.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  word-wrap: break-word;
}
.information-title {
  color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: left;
}
.content-container {
  border-radius: 8px;
  background: rgb(255, 255, 255);
  max-width: 533px;
  padding: 10px 10px 20px 10px;
}
::v-deep.v-tabs .v-tabs-bar .v-tabs-slider {
  background-color: #00b800 !important; /* Укажите желаемый цвет для индикатора */
}
::v-deep .v-tab:before {
  background-color: gray;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
::v-deep .v-tab {
  caret-color: #ff0000 !important;
  color: #333;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  font-size: 1.1rem;
  font-weight: 600;
  font-style: normal;
  justify-content: center;
  letter-spacing: normal;
  line-height: normal;
  min-width: 90px;
  max-width: 360px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: none;
  transition: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-family: "Inter";
}
.breadcrums-click {
  cursor: pointer;
}
.breadcrums-title {
  color: var(--02, #848484);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  text-decoration: none;
}
.breadcrums-container {
  display: flex;
  align-items: center;
  margin-top: 7px;
}
::v-deep .v-input__slot {
  border-style: none;
  margin-bottom: 0px;
}

.title-container {
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 17px;
}
.img-pluss-btn {
  margin-left: 10px;
}
.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  /* width: 56px; */
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
/* .top-panel .container {
        width: 100%;
        padding: 0px !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
      } */
.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}
.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}
.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}
.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black; /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}
.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin-left: 37px;
  cursor: pointer;
}

.top-panel {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  word-wrap: break-word;
}
@media (min-width: 1264px) {
  .container {
    /* //1185px дефолтное название если что то ломаться начнется на маленьких мониторах */
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  /* //900 дефолтное название если что то ломаться начнется на маленьких мониторах */
  .container {
    max-width: 100% !important;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "ProductPage",
  directives: { mask },
  // props: {
  //   requestData: {},
  //   responseData: {},
  // },

  created() {
    // this.uploadedImageLogo =
    //   process.env.VUE_APP_API_HOST + this.responseData.logo.imagePath;
  },

  beforeDestroy() {
    window.removeEventListener("click", this.handleClickOutside);
  },

  data() {
    return {
      product: {},
      isHovered: false,
      loading: false,
      showInputMenu: false,
      selectedOption: "",
      selectedImage: null,
      uploadedImageLogo: "",
      isPlaceholderHidden: false,
      isproductNameFocused: false,
      isdescriptionFocused: false,
      productName: "",
      description: "",
      price: "",
      selectedRoles: null,
      imgPreview: false,
      roleTranslations: {
        Owner: "Владелец",
        Administrator: "Администратор",
      },
    };
  },

  methods: {
    getUserMember() {
      this.$http
        .get(`/api/Product/${this.$route.params.productId}`)
        .then((response) => {
          this.product = response.data;
          this.productName = response.data.name;
          this.description = response.data.description;
          this.selectedOption = response.data.category.name;
          this.price = response.data.price + "₸";
          this.loading = true;
          if (response.data.images.length && response.data.images[2]) {
            this.uploadedImageLogo =
              process.env.VUE_APP_API_HOST + response.data.images[2].imagePath;
          }
          console.log(this.uploadedImageLogo);
        })
        .catch((error) => {
          alert(error);
        });
    },

    openImagePreview() {
      this.imgPreview = true;
    },
    closeImagePreview() {
      this.imgPreview = false;
    },
    formatpriceInput() {
      // Удаляем все символы, кроме цифр
      let value = this.price.replace(/\D/g, "");

      // Если строка пуста, не выполняем форматирование
      if (value === "") {
        this.price = "";
        return;
      }

      // Форматируем число с пробелами в качестве разделителя тысяч
      value = parseInt(value).toLocaleString("ru-RU").replace(/,/g, " ");

      // Добавляем символ в конце
      value += "₸";

      // Обновляем значение модели
      this.price = value;
    },

    clearCurrencySymbolprice(event) {
      if (event.key === "Backspace" && this.price.length > 0) {
        this.price = this.price.slice(0, -1); // Удаляем последний символ
      }
    },

    uploadImageLogo(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedImage = file;
        this.uploadedImageLogo = URL.createObjectURL(file);
      }
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },

    hidePlaceholder(inputName) {
      if (inputName === "productName") {
        this.isproductNameFocused = true;
      } else if (inputName === "actualAdress") {
        this.isActualAdressFocused = true;
      }
    },
    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      this.activeTextarea = null;
    },
    addBorderClass() {
      this.isHovered = true;
    },
    removeBorderClass() {
      this.isHovered = false;
    },
    handleClickOutside(event) {
      const inputButton = this.$refs.inputButton;
      const inputMenu = this.$refs.inputMenu;

      if (
        inputButton &&
        inputMenu &&
        !inputButton.contains(event.target) &&
        !inputMenu.contains(event.target)
      ) {
        this.showInputMenu = false;
      }
    },
    toggleInputMenu() {
      this.isHovered = false;
      this.showInputMenu = !this.showInputMenu;
    },

    selectOption() {
      // this.selectedOption = option;
      this.selectedOption = this.category.name;
      this.toggleInputMenu(); // Вызовите метод для скрытия меню (если это требуется)
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },
  },
  mounted() {
    // this.initialize();
    //   this.getManufacturers();
    this.getRestaurant();
    window.addEventListener("click", this.handleClickOutside);
  },

  watch: {
    productName() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("productNameTextarea");
      });
    },
    description() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("descriptionTextarea");
      });
    },
  },
};
</script>
